"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Morph = void 0;
class Morph {
    constructor(morphable) {
        this.morphable = morphable;
        this.morphable = morphable;
    }
    animate(delta, options, callable = null) {
        let { toggle, key, speed = 1 } = options;
        if (typeof this.morphable.morphTargetInfluences === 'undefined'
            || typeof this.morphable.morphTargetDictionary === 'undefined')
            throw new Error('No Morph Target Dictionary or Influences.');
        let index = this.morphable.morphTargetDictionary[key];
        let currentValue = this.morphable.morphTargetInfluences[index];
        let sign = toggle ? 1 : -1;
        let value = currentValue + sign * (delta * speed);
        value = value > 1 ? 1 : value;
        this.morphable.morphTargetInfluences[index] = value;
        let result = toggle ? value < 1 : value > 0;
        if (!result && callable)
            callable();
        return result;
    }
}
exports.Morph = Morph;
