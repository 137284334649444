"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Animation = void 0;
const three_1 = require("three");
class Animation {
    constructor(model) {
        this.mixer = new three_1.AnimationMixer(model.scene);
        this.model = model;
    }
    play() {
        this.mixer.clipAction(this.model.animations[0]).play();
    }
    animate(delta) {
        this.mixer.update(delta);
    }
}
exports.Animation = Animation;
