"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hero = void 0;
const three_1 = require("three");
const GLTFLoader_1 = require("three/examples/jsm/loaders/GLTFLoader");
const Animation_1 = require("../3d/Animation");
const Morph_1 = require("../3d/Morph");
const Job_1 = require("../3d/Job");
const CSS2DRenderer_1 = require("three/examples/jsm/renderers/CSS2DRenderer");
const OrbitControls_1 = require("three/examples/jsm/controls/OrbitControls");
class Hero {
    constructor() {
        this.scene = new three_1.Scene();
        this.camera = new three_1.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 10);
        this.renderer = new three_1.WebGLRenderer({
            antialias: true,
            canvas: document.getElementById('hero'),
            alpha: true
        });
        this.animations = [];
        this.clock = new three_1.Clock();
        this.animationClosures = [];
        this.headMorph = null;
        this.curve = null;
    }
    init() {
        // this.scene.background = new Color( 0xf6f9ff );
        this.scene.background = null;
        this.createLights();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setAnimationLoop(this.animate.bind(this));
        this.renderer.outputEncoding = three_1.sRGBEncoding;
        this.loadCharacter();
        $(window).on('resize', () => this.onResize());
        // this.stats = new (Stats as any)();
        // document.body.append(this.stats.dom)
    }
    onResize() {
        var _a;
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        (_a = this.labelRenderer) === null || _a === void 0 ? void 0 : _a.setSize(window.innerWidth, window.innerHeight);
        this.positionTooltip();
    }
    createLights() {
        let ambientLight = new three_1.AmbientLight(0x61f4ff, 0.2);
        this.scene.add(ambientLight);
        let light = new three_1.DirectionalLight(0xffffff, 1);
        light.position.set(0.5, 1, 5);
        this.scene.add(light);
    }
    createControls() {
        let _this = this;
        this.camera.position.set(0, 0.55, 0.9);
        this.controls = new OrbitControls_1.OrbitControls(this.camera, this.renderer.domElement);
        this.bbox = new three_1.Box3().setFromObject(this.char);
        this.controls.minDistance = 0.9;
        this.controls.maxDistance = 1.15;
        this.controls.minPolarAngle = Math.PI / 2.25;
        this.controls.maxPolarAngle = Math.PI / 1.8;
        this.controls.minAzimuthAngle = -Math.PI / 18;
        this.controls.maxAzimuthAngle = Math.PI / 18;
        // this.camera.lookAt(new Vector3(0,(this.bbox.max.y + this.bbox.min.y) /2,0))
        this.controls.target.copy(new three_1.Vector3(0, this.bbox.max.y, 0));
        this.controls.update();
        $(this.controls.domElement).on('wheel', function (e) {
            var _a;
            // @ts-ignore
            let scrollDirection = Math.sign((_a = e.originalEvent) === null || _a === void 0 ? void 0 : _a.deltaY);
            if (scrollDirection === 1 && _this.controls.getDistance() < _this.controls.maxDistance * 0.9 ||
                scrollDirection === -1 && _this.controls.getDistance() > _this.controls.minDistance * 1.1)
                return;
            $(document).scrollTop($(document).scrollTop() + scrollDirection * window.innerHeight * 0.25);
        });
        this.controls.addEventListener('change', function () {
            _this.positionTooltip();
        });
    }
    loadCharacter() {
        let _this = this;
        let gltfLoader = new GLTFLoader_1.GLTFLoader();
        gltfLoader.load('/3d/char.glb', // called when the resource is loaded
        function (gltf) {
            _this.char = gltf.scene;
            gltf.scene.scale.multiplyScalar(0.5);
            _this.scene.add(gltf.scene);
            let animation = new Animation_1.Animation(gltf);
            animation.play();
            _this.animations.push(animation);
            _this.createControls();
            _this.createToolTip();
            // ignore this because I know my object has it
            // @ts-ignore
            _this.headMorph = new Morph_1.Morph(gltf.scene.getObjectByName("Armature").getObjectByName('Wolf3D_Head'));
            let closeEyes = new Job_1.Job();
            //push job into the animation closures
            _this.animationClosures.push((time, delta) => {
                return closeEyes.continuesEvery(time, 30, () => {
                    var _a;
                    return (_a = _this.headMorph) === null || _a === void 0 ? void 0 : _a.animate(delta, {
                        toggle: true,
                        key: "eyesClosed",
                        speed: 2
                    }, () => {
                        _this.animationClosures.push((time, delta) => {
                            var _a;
                            return (_a = _this.headMorph) === null || _a === void 0 ? void 0 : _a.animate(delta, {
                                toggle: false,
                                key: "eyesClosed",
                                speed: 2
                            });
                        });
                    });
                });
            });
            $("#hero-spinner").addClass('d-none');
        }, 
        // called while loading is progressing
        function (xhr) {
        }, 
        // called when loading has errors
        function (error) {
            console.log('An error happened');
        });
    }
    createToolTip() {
        const tooltip = $("#landing .arrow_box");
        tooltip.removeClass('d-none');
        // const tooltip = document.createElement( 'div' );
        // tooltip.className = 'label';
        // tooltip.textContent = 'Earth';
        // tooltip.style.marginTop = '-1em';
        this.tooltip = new CSS2DRenderer_1.CSS2DObject(tooltip[0]);
        this.char.add(this.tooltip);
        this.labelRenderer = new CSS2DRenderer_1.CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth, window.innerHeight);
        this.labelRenderer.domElement.id = 'labelRenderer';
        $("#landing").append(this.labelRenderer.domElement);
        this.positionTooltip();
    }
    positionTooltip() {
        var _a, _b;
        (_a = this.tooltip) === null || _a === void 0 ? void 0 : _a.position.set(this.bbox.min.x, this.bbox.max.y + 1, this.bbox.max.z);
        (_b = this.labelRenderer) === null || _b === void 0 ? void 0 : _b.render(this.scene, this.camera);
    }
    animate(time) {
        var _a;
        let _this = this;
        let delta = this.clock.getDelta();
        if (this.animations) {
            this.animations.forEach(animation => {
                animation.animate(delta);
            });
        }
        this.renderer.render(this.scene, this.camera);
        if (this.animationClosures.length > 0) {
            this.animationClosures.forEach(function (closure, index) {
                try {
                    if (typeof closure !== "function" || !closure(time, delta)) {
                        _this.animationClosures.splice(index, 1);
                    }
                }
                catch (e) {
                    console.error(e);
                    console.error('Error caught, exiting the closures loop now');
                    _this.animationClosures.splice(index, 1);
                }
            });
        }
        (_a = this.stats) === null || _a === void 0 ? void 0 : _a.update();
    }
    box() {
        let size = 0.5;
        const geometry = new three_1.BoxGeometry(size, size, size);
        const material = new three_1.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new three_1.Mesh(geometry, material);
        cube.position.set(this.bbox.min.x, this.bbox.max.y, this.bbox.max.z);
        this.char.add(cube);
    }
}
exports.Hero = Hero;
