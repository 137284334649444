"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Job = void 0;
class Job {
    constructor() {
        this.deciSecondsTracker = 0;
        this.continues = false;
        // every(animateTime: number,secondsToRunTheCallable: number,callable : Function){
        //     let newSecond = Math.trunc(animateTime / 1000);
        //     if(secondsTracker !== newSecond)
        //     {
        //         secondsTracker = newSecond;
        //         if(secondsTracker % secondsToRunTheCallable === 0)
        //         {
        //             callable();
        //         }
        //     }
        //     return true;
        // }
    }
    continuesEvery(animateTime, deciSecondsToRunTheCallable, callable) {
        let newDeci = Math.trunc(animateTime / 100);
        if (this.continues) {
            this.continues = callable();
            return true;
        }
        if (this.deciSecondsTracker !== newDeci) {
            this.deciSecondsTracker = newDeci;
            if (this.deciSecondsTracker % deciSecondsToRunTheCallable === 0) {
                this.continues = callable();
            }
        }
        return true;
    }
}
exports.Job = Job;
