"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
let nav = $("header nav");
let mobileBar = $("#mobile-menu-bars");
class Menu {
    open() {
        nav.addClass('opened');
        mobileBar.one('transitionend', () => {
            mobileBar.removeClass('fa-bars').addClass('fa-times').css({
                'transform': 'rotateY(0deg)'
            });
        }).css({
            'transform': 'rotateY(90deg)'
        });
    }
    close() {
        nav.removeClass('opened');
        mobileBar.one('transitionend', () => {
            mobileBar.removeClass('fa-times').addClass('fa-bars').css({
                'transform': 'rotateY(0)'
            });
        }).css({
            'transform': 'rotateY(90deg)'
        });
    }
    toggle() {
        if (nav.hasClass('opened')) {
            this.close();
        }
        else {
            this.open();
        }
    }
}
let menu = new Menu();
exports.default = menu;
